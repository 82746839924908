import { withTranslation } from "react-i18next";
import {  StyledInput } from "./styles";
import { Label } from "./styles";
import { InputProps } from "../types";

const Inputnew = ({ name, placeholder, onChange, t }: InputProps) => (

    <><Label htmlFor={name}>{t(name)}</Label><StyledInput
    placeholder={t(placeholder)}
    name={name}
    id={name}
    onChange={onChange} /></>

);

export default withTranslation()(Inputnew);
