import Input from '../../common/Input';
import { useFormsignup } from '../../common/utils/useFormsignup';
import { Container, StyledButton, FormGroup, Span} from "../../common/ContentBlockCss/styles"
import { Styles } from "../../styles/styles";
import InputPassword from '../../common/PasswordInput';
import { useState } from 'react';


function SignupPage() {
  
  const [emailvalid, setEmailvalid] = useState(false);
  const [passvalid, setPassvalid] = useState(false);
 
    const { values, errors, handleChange, handleSubmit } = useFormsignup();
 const ValidationEmail = () => {
      let errorMessage:string = ""; 
     if (values.email && !/\S+@\S+\.\S+/.test(values.email)) {
      errorMessage = "Email address is invalid";
      setEmailvalid(false);
      }
      else {setEmailvalid(true)}
      return <Span>{errorMessage}</Span>;
      };

      const ValidationPassword = () => {
        let errorMessage:string = ""; 
        if ((values.password.length < 8) || !/\d/.test(values.password)) {
         errorMessage = "Password must at least 8 chracters + one number";
         setPassvalid(false);
         }
         else {setPassvalid(true)}
         return <Span>{errorMessage}</Span>;
      }
       
   // const handleSubmit = (e: { preventDefault: () => void; }) => {
      //  e.preventDefault();

        // validate password and set passwordInvalid state accordingly
      //  if (password.length < 8) {
         //   setPasswordInvalid(true);
     //   } else {
          //  setPasswordInvalid(false);
     //   }
  //  }

  ////  const usernameEntered = (e: React.ChangeEvent<HTMLInputElement>) => {
       // setUsername(e.target.value);
        // buttonEnabled(username, password)
  //  }

  //  const passwordEntered = (e: React.ChangeEvent<HTMLInputElement>) => {
   //     setPassword(e.target.value);
        // buttonEnabled(username, password)
  //  }

   // const buttonEnabled = (username: string | any[], password: string | any[]) => {
    //    if(username.length > 0 && password.length > 0) {
    //        setEnabled(true);
    //    } else {
     //       setEnabled(false);
    //    }
  //  }

    return (
        <>
        <Container>
        <Styles />
        <FormGroup autoComplete="off" onSubmit={handleSubmit}>
        <Input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={values.name || ""}
                  onChange={handleChange} />
                <Input
                  type="text"
                  name="email"
                  placeholder="Your Email"
                  value={values.email || ""}
                  onChange={handleChange} />
                <ValidationEmail />
                <InputPassword
                  type="text"
                  name="password"
                  placeholder="Your Password"
                  value={values.password || ""}
                  onChange={handleChange} />
                 <ValidationPassword />  
                 <div>          
                 </div>
                <StyledButton name="submit" disabled={!values.name || !emailvalid || !passvalid }>{("Submit")}</StyledButton>
            
            </FormGroup>
        </Container> </>
     
    )
    //</StyledForm>
    //<StyledForm onSubmit={handleSubmit}>
    //tyledInput type="text" value={username} onChange={e => usernameEntered(e)}
    //invalid={passwordInvalid}
    //StyledInput type="password" value={password} onChange={(e) => passwordEntered(e)
}

export default SignupPage;