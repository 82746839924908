import { BrowserRouter } from "react-router-dom";
//import ReactDOM from "react-dom";
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";

import Router from "./router";
import i18n from "./translation";
import React from "react";
import { register } from "register-service-worker";
import { registerServiceWorker } from "./serviceWorker";
//import SuperTokens from "supertokens-auth-react";
//import { SuperTokensConfig} from './config';
import { registerSW } from 'virtual:pwa-register';

//SuperTokens.init(SuperTokensConfig);

const App = () => (

  
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
//ReactDOM.render(<App />, document.getElementById("root"));
//serviceWorkerRegistration.register();
//register(`/service-worker.ts`)
//if ('serviceWorker' in navigator) {
  //registerSW();
//} uncomment below 
registerServiceWorker();

