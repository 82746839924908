import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";
//import { SvgIcon } from "../../common/SvgIcon";
import {StyledButtonsign} from "../../common/Button/styles";
//import { Button } from "../../common/Button";

import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { useNavigate } from "react-router-dom";
import Session, { signOut} from "supertokens-web-js/recipe/session";




const Header = ({ t }: { t: TFunction }) => {
  const [visible, setVisibility] = useState(false);
  const [logedin, setLogedin] = useState(false);

  const toggleButton = () => {
    setVisibility(!visible);
  };


  



  const MenuItem = () => {

  const navigate = useNavigate();
   

async function ToAuth() {

   Promise.all([await Session.doesSessionExist(), await Session.getUserId() ])
  .then(results => {
    let userId  = results[1];
    console.log("use id:", userId);
      setLogedin(true);    
    
})
.catch(function(err) {
  console.log(err.message); 
  setLogedin(false); 
  navigate("/login");
  setVisibility(false);
});

console.log("state:", logedin);
      if (logedin)
      {
       if (window.confirm('Are you sure you want to sign out?')) {
        await signOut();
        alert("Sign Out Successful");
        window.location.href = "/"; 
        } else {
          // Do nothing!
         // console.log('somthing.');
        }
        }
      else {
      navigate("/login");
      setVisibility(false);}
  };


    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (

      //, () => scrollTo("contact")   <SvgIcon src="logo.svg" width="101px" height="64px" />
      <>
        <CustomNavLinkSmall onClick={() => scrollTo("about")}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <Span>{t("Mission")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={ToAuth} 
        >
          <Span>
            <StyledButtonsign  name="Sign in" color= {logedin ?"#FF825C" : "#37ab14" }>{logedin ? t("Sign out") : t("Sign in")}</StyledButtonsign>
            
          </Span>
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    
    <HeaderSection>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
          
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
