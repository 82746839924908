import { usePWAInstall } from 'react-use-pwa-install'


export default function Pwainstall()  {
	const install = usePWAInstall();
	const btn = {
        backgroundColor: '#FF5733',
        color: 'white',
        borderRadius: '20px',
       padding: '10px 20px',
        margin: '10px',
        textContent: 'The APP only takes less 1 MB storage and easy to remove, why not try to install it',
        border: 'none',
        
    };


    
	return (
   
		
		
        install && <><button style={btn} onClick={install}>Install</button>
		<div role="alert">
			APP only takes less 1 MB storage and easy to remove, why not try to install it
		</div></>
  
    
	);
    
		
	
}