import CallAPIView from "../../common/Auth/CallAPIView";
import Container from "../../common/Container";
import UserContentBlock from "../../components/UserContentBlock";
import CodeScan from "../../components/CodeScan";
import UserContentBlockContent from "../../content/UserContentBlockContent.json";
import CodeScanContent from "../../content/CodeScanContent.json";

const UserPage = () => {

    return(
      <Container>
 <UserContentBlock
        direction="left"
        title={UserContentBlockContent.title}
        content={UserContentBlockContent.text}
        icon=""
        id="qr code"
      />
      <CodeScan
        direction="left"
        title={CodeScanContent.title}
        content={CodeScanContent.text}
        icon=""
        id="qr code"
      />

      </Container>

 
    )
}

export default UserPage; 