
import styled from "styled-components";

export const Label = styled("label")`
   display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: ${props => props.color ? 'red' : 'black'};
`;
export const PasswordInput = styled.input.attrs(props => ({
  type: "password"
}))`
      width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;`;