import { lazy } from "react";
import { Styles } from "../../styles/styles";
import Header from "../../components/Header";
import IntroContent from "../../content/IntroContent.json";
import MiddleBlockContent from "../../content/MiddleBlockContent.json";
import DescriptionContent from "../../content/DescriptionContent.json";
import MissionContent from "../../content/MissionContent.json";
import ProductContent from "../../content/ProductContent.json";
import ContactContent from "../../content/ContactContent.json";
//const Contact = lazy(() => import("../../components/ContactForm"));
//const MiddleBlock = lazy(() => import("../../components/MiddleBlock"));
//const Container = lazy(() => import("../../common/Container"));
//const ScrollToTop = lazy(() => import("../../common/ScrollToTop"));
//const ContentBlock = lazy(() => import("../../components/ContentBlock"));
import MiddleBlock from "../../components/MiddleBlock";
import Container from "../../common/Container";
import ScrollToTop from "../../common/ScrollToTop";
import ContentBlock from "../../components/ContentBlock";
import Pwainstall from "../../components/Pwainstall";

const Home = () => {
  return (
    
    <Container>
        <Styles />
        <Header />
      <ScrollToTop />
      <ContentBlock
        direction="right"
        title={IntroContent.title}
        content={IntroContent.text}
        button={IntroContent.button}
        icon="world.svg"
        id="intro"
      />
      <MiddleBlock
        title={MiddleBlockContent.title}
        content={MiddleBlockContent.text}
        button={MiddleBlockContent.button}
      />
      <ContentBlock
        direction="left"
        title={MissionContent.title}
        content={MissionContent.text}
        section={MissionContent.section}
        icon="bag code.svg"
        id="mission"
      />
      <ContentBlock
        direction="right"
        title={DescriptionContent.title}
        content={DescriptionContent.text}
        icon="bag recycle.svg"
        id="description"
      />
      <ContentBlock
        direction="left"
        title={ProductContent.title}
        content={ProductContent.text}
        icon="code recycle.svg"
        id="product"
      />
        <Pwainstall />
          </Container>
  );
};

export default Home;
