import { SetStateAction, useState } from "react";
import { Row, Col, Result } from "antd";
import { withTranslation } from "react-i18next";
import { ContentBlockProps } from "../../common/ContentBlockCss/types";
import { Button } from "../../common/Button";
import { SvgIcon } from "../../common/SvgIcon";
import { useNavigate } from "react-router-dom";
import {
  ContentSection,
  Content,
  ContentWrapper,
  ServiceWrapper,
  MinTitle,
  MinPara,
  StyledRow,
  Span,
} from "../../common/ContentBlockCss/styles";
import { error } from "console";
import {QrReader} from "react-qr-reader";


const CodeScan = ({
  t,

}: ContentBlockProps) => {

  const [startScan, setStartScan] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [data, setData] = useState("");
  const [facingMode, setFacingMode] = useState("environment");

  const navigate = useNavigate();
  const  ToAuth =() => {
    navigate("/user");
  };
  const handleScan = (result: any, error: any) => {
    if (!!result) {
      setData(result?.text);
      setStartScan(false);
      setLoadingScan(false);
    }
   else if (!!error) {
      console.info(error);
    }

    //else{setTimeout(function () { alert("wikipedia Qr code "); }, 1000);}
    }

  return (
    
<>     
    
            <Span>
              <Button
                onClick={() => {  
                  setData("");
                  setStartScan(!startScan);
                  setLoadingScan(!loadingScan);

                } }
                name="Scan now">{startScan ? t("Stop scan") : t("Scan now")}
              </Button>
            </Span>
{startScan && (
  
    <><select onChange={(e) => setFacingMode(e.target.value)}>
          <option value={"environment"}>Back Camera</option>
          <option value={"user"}>Front Camera</option>
        </select>
        <QrReader
            constraints={{
              facingMode,
            }}
            videoStyle={{width: 300, height: 300, border: 'solid', borderWidth: '4px', borderColor: data.length === 0 ? 'green' : 'blue', }}
            scanDelay={500}
            onResult={handleScan}
            
             
//ViewFinder={scanOverlay}
           // onResult={(result, error) => {
              
                //if (result.toString !== "https://tolkida.sytes.net/") { setTimeout(function () { alert("Invalid Qr code "); }, 1000); }
               // else {
                 
                  //alert("A bulk of 20 Recyle stickers has been added to your account, Thank you");
                 // console.log(result.toString);
               // setData();
               // }
             // }
             
            //  if (!!error) {
             //   console.info(error);
            //  }
        //    } 
         // }
         
          /></>

)}
{loadingScan && <p>Loading</p>}
{data !== "" && <p>{data}</p>}

<div>
<p></p>
</div>
<div>
<p></p>
</div>
         </>
            
        

  
  );
};

export default withTranslation()(CodeScan);
