import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";

import { ContentBlockProps } from "../../common/ContentBlockCss/types";
import { SvgIcon } from "../../common/SvgIcon";
import {
  ContentSection,
  Content,
  ContentWrapper,
  ServiceWrapper,
  MinTitle,
  MinPara,
  StyledRow,
} from "../../common/ContentBlockCss/styles";
import { Button } from "../../common/Button";
import CodeScan from "../CodeScan";


const UserContentBlock = ({
  title,
  content,
  section,
  t,
  id,
  direction,
}: ContentBlockProps) => {


  return (
    <><ContentSection>
      <StyledRow
        justify="space-between"
        align="middle"
        id={id}
        direction={direction}
      >
        <Col lg={22} md={22} sm={22} xs={24}>
          <ContentWrapper>
            <h6>{t(title)}</h6>
            <Content>{t(content)}</Content>



            <ServiceWrapper>
              <Row justify="space-between">
                {typeof section === "object" &&
                  section.map(
                    (
                      item: {
                        title: string;
                        content: string;
                        icon: string;
                      },
                      id: number
                    ) => {
                      return (
                        <Col key={id} span={11}>
                          <SvgIcon
                            src={item.icon}
                            width="50px"
                            height="50px" />
                          <MinTitle>{t(item.title)}</MinTitle>
                          <MinPara>{t(item.content)}</MinPara>
                        </Col>
                      );
                    }
                  )}
              </Row>
            </ServiceWrapper>
          </ContentWrapper>
        </Col>
      </StyledRow>
    </ContentSection>
    
    </>
       
  );
};

export default withTranslation()(UserContentBlock);
